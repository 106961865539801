import { autoinject } from "aurelia-framework";
import * as Fhir from "../../../resources/classes/FhirModules/Fhir";
import { Router } from "aurelia-router";
import { I18N } from "aurelia-i18n";
import { DialogMessages } from "resources/services/DialogMessages";
import { SmileService } from "resources/services/SmileService";
import { LocationService } from "resources/services/LocationService";
import { fhirEnums } from "../../../resources/classes/fhir-enums";
import { FhirService } from "../../../resources/services/FhirService";
import SystemHeaders from "../../../resources/classes/SystemHeaders";
import { NitTools } from "../../../resources/classes/NursitTools";
import { RuntimeInfo } from "../../../resources/classes/RuntimeInfo";
import { ISmileUser } from "../interfaces/ISmileUser";
import { QualificationCodes } from "../../../resources/elements/qualification-codes";
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;
import { ConfigService } from "../../../resources/services/ConfigService";
import { UserService } from "../../../resources/services/UserService";

const moment = require("moment");

@autoinject
export class User {
    wards;
    qualifications;

    /** The abp-datepicker options */
    dpOptions = {
        format: RuntimeInfo.DateFormat,
        locale: RuntimeInfo.Language,
        showTodayButton: true,
        showClear: false,
        showClose: true,
        widgetPositioning: {
            horizontal: "left",
            vertical: "auto",
        },
        focusOnShow: false,
    };

    editMode = false;
    prompt: boolean = true;
    noUser = false;
    noPractitioner = false;

    public userData: ISmileUser;
    practitionerData: any;
    practitionerRoleData: any;

    public username: string;
    public password: string;
    permissionRole;
    public isActive: boolean;
    isSystem;
    namePrefix;
    public firstName: string;
    public lastName: string;
    mobilePhone;
    gender;
    dateValue;
    dateObject;
    startupWard;
    isSaving = false;
    
    /** Indicates whether to warn the user if no roleViews have been found in the config. Toggle to stop flooding the console */
    static roleViewWarningShown: boolean = false;
    domains: IUserDomain[] = [];

    /** the hardcoded user-domains we always ensure to be avialable */
    public defaultDomains: IUserDomain[] = [
        { role: "controlling", title: "Controlling" },
        { role: "pharmacy", title: "Pharmacy" },
        { role: "nurse", title: "Nurse" },
        { role: "admin", title: "Admin" },
        { role: "user", title: "User" },
        { role: "doctor", title: "Doctor" },
        { role: "theraphy", title: "Theraphy" },
        { role: "socialservice", title: "Socialservice" },
        { role: "trainee", title: "Trainee" },
        { role: "designer", title: "Form-Designer" }
    ];

    constructor(
        protected router: Router,
        protected dialogMessages: DialogMessages,
        protected smileService: SmileService,
        protected i18n: I18N,
        protected fhirService: FhirService,
        protected locationService: LocationService,
    ) {
        if (ConfigService.Debug) 
            window["userAdmin"] = this;if (ConfigService.Debug) 
                window["userAdmin"] = this;

        // read the qualifications - don't mess up with Domains. Quali is something like HH, 24H..
        this.qualifications = [];
        for (const key of Object.keys(QualificationCodes)) {
            this.qualifications.push({
                id: NitTools.UidName(),
                value: key,
                text: QualificationCodes[key],
                selected: false,
            });
        }

        // translate the default domains if applicable
        for (const dom of this.defaultDomains) {
            const key = `domain_${dom.role}`;
            const translated = this.i18n.tr(key);
            if (key !== translated) // don't update the title when no translation exists ..
                dom.title = translated
        }
    }

    async activate(params) {
        await this.loadWards();

        const roleViews = ConfigService.cfg?.roleViews;
        if (!roleViews && !User.roleViewWarningShown) {
            console.warn("roleViews Property not set. Consider updating the setup.json to include rolebase views");
            User.roleViewWarningShown = true;
        } else {
            const doms = this.defaultDomains.map(o=>o.role);
            this.domains = roleViews.filter(o => doms.indexOf(o.role) === -1);
        }

        if (params.user) {
            this.editMode = true;

            await this.loadUser(params.user);
        } else {
            this.isActive = true;
            this.permissionRole = "user";
        }
    }

    async loadWards() {
        let _wards: any[] = await this.fhirService.fetch(
            `Location?identifier=${SystemHeaders.systemBase}locationWard|`,
            true,
        );
        // let _wards : any[] = this.locationService.wards;

        this.wards = _wards.map((ward) => {
            return {
                id: ward.id,
                name: ward.name,
                isSelected: false,
            };
        });

        (<any[]>this.wards).sort((a, b) => {
            return String(a.name).localeCompare(String(b.name));
        });
    }

    public static DefaultUserAuthorities = [
        { permission: "ROLE_FHIR_CLIENT" },
        { permission: "FHIR_CAPABILITIES" },
        { permission: "FHIR_ALL_WRITE" },
        { permission: "FHIR_BATCH" },
        { permission: "FHIR_TRANSACTION" },
        { permission: "FHIR_ALL_READ" },
        { permission: "CHANGE_OWN_PASSWORD" },
        { permission: "CHANGE_OWN_DEFAULT_LAUNCH_CONTEXTS" },
        { permission: "VIEW_USERS" },
        { permission: "FHIR_ALL_DELETE" },
        { permission: "FHIR_META_OPERATIONS_SUPERUSER" },
        { permission: "FHIR_OP_ENCOUNTER_EVERYTHING" },
        { permission: "FHIR_OP_PATIENT_EVERYTHING" },
        { permission: "ACCESS_ADMIN_JSON" },
    ];

    async loadUser(username) {
        this.userData = await this.smileService.getUser(username);

        if (this.userData) {
            if (!this.userData.authorities) {
                this.userData.authorities = User.DefaultUserAuthorities;
            }

            this.noUser = false;
            this.username = this.userData.username;
            this.isActive = !this.userData.accountDisabled;
            this.isSystem = this.userData.systemUser;

            const p = await this.loadPractitioner();
            if (this.currentUserIsAdmin()) {
                this.permissionRole = "admin";
            } else if (this.currentUserIsAnonymous()) {
                this.permissionRole = "anonymous";
            } else {
                this.permissionRole = p.permission;
            }
        } else {
            this.noUser = true;
        }

        return this.userData;
    }

    async loadPractitioner() {
        // if (UserService.Practitioner) this.practitionerData = UserService.Practitioner;
        // if (UserService.Role) this.practitionerRoleData = UserService.Role;

        if (!this.practitionerRoleData || !this.practitionerData) {
            const practitionerResult = await Fhir.Rest.Fetch(
                `Practitioner?active=true&identifier=${this.userData.username}&_revinclude=PractitionerRole:practitioner`,
            );

            this.practitionerData = <any>(
                practitionerResult.find(
                    (res) =>
                        res.resourceType ===
                        fhirEnums.ResourceType.practitioner,
                )
            );

            this.practitionerRoleData = <any>(
                practitionerResult.find(
                    (res) =>
                        res.resourceType ===
                        fhirEnums.ResourceType.practitionerRole,
                )
            );

            if (this.practitionerData && this.practitionerRoleData) {
                this.updatePractitionerDataFromFhirPractitioner();
            } else {
                this.noPractitioner = true;
            }
        }

        let permission =
            UserService.GetUserRole(this.practitionerData) || "user";
        return {
            practitioner: this.practitionerData,
            role: this.practitionerRoleData,
            permission: permission,
        };
    }

    updatePractitionerDataFromFhirPractitioner() {
        if (!this.practitionerData) {
            this.noPractitioner = true;
            return;
        }

        this.noPractitioner = false;

        let name =
            this.practitionerData.name &&
            this.practitionerData.name.find((name) => name.use === "official");

        if (!name)
            name = this.practitionerData.name && this.practitionerData.name[0];

        if (name) {
            this.namePrefix = name.prefix ? name.prefix[0] : "";
            this.firstName = name.given ? name.given[0] : "";
            this.lastName = name.family;
        }

        if (this.practitionerData.qualification) {
            for (const coding of this.practitionerData.qualification
                .filter((o) => o.code && o.code.coding && o.code.coding[0])
                .map((p) => p.code.coding[0])) {
                const item = this.qualifications.find(
                    (o) => o.value == coding.code,
                );
                if (item) item.selected = true;
            }
        }

        if (!this.practitionerData.identifier)
            this.practitionerData.identifier = [];
        if (this.permissionRole) {
            // remove existing user role
            this.practitionerData.identifier =
                this.practitionerData.identifier.filter(
                    (o) => o.system.indexOf("/smile-user-role") === -1,
                );

            // add new permission
            this.practitionerData.identifier.push({
                system: `${RuntimeInfo.SystemHeader}'/smile-user-role`,
                value: this.permissionRole,
            });
        }

        const mobile =
            this.practitionerData.telecom &&
            this.practitionerData.telecom.find(
                (telecom) =>
                    telecom.system === "phone" && telecom.use === "mobile",
            );

        if (mobile) {
            this.mobilePhone = mobile.value;
        }

        this.gender = this.practitionerData.gender;

        if (this.practitionerData.birthDate) {
            this.dateObject = moment(this.practitionerData.birthDate).toDate();
        }

        if (this.practitionerRoleData.location && this.wards) {
            this.practitionerRoleData.location.forEach((location) => {
                const locationId = location.reference.split("/")[1];
                const ward = this.wards.find((ward) => ward.id === locationId);

                if (ward) ward.isSelected = true;
                else {
                    console.info(`Ward with id="${locationId}" not found!`);
                }

                if (
                    location.identifier &&
                    location.identifier.system ===
                        RuntimeInfo.SystemHeader +
                            "/practitioner-default-ward" &&
                    location.identifier.value === "default"
                ) {
                    this.startupWard = locationId;
                }
            });
        }
    }

    async createPractitioner() {
        const practitioner = {
            resourceType: fhirEnums.ResourceType.practitioner,
            identifier: [
                {
                    system: RuntimeInfo.SystemHeader + "/smile-account-id",
                    value: this.userData.username.toUpperCase(),
                },
                {
                    system: `${RuntimeInfo.SystemHeader}/smile-user-role`,
                    value: this.permissionRole,
                },
                {
                    system: RuntimeInfo.SystemHeader + "/smile-user-pid",
                    value: this.userData.pid,
                },
            ],
            active: !this.userData.accountDisabled,
        };

        if (!this.practitionerData) {
            this.practitionerData = <any>await Fhir.Rest.Create(practitioner);
        }

        const practitionerRole = {
            resourceType: fhirEnums.ResourceType.practitionerRole,
            active: true,
            practitioner: {
                reference: `Practitioner/${this.practitionerData.id}`,
            },
        };

        this.practitionerRoleData = <any>(
            await Fhir.Rest.Create(practitionerRole)
        );

        this.updatePractitionerDataFromFhirPractitioner();
    }

    validate() {
        if (!this.username) {
            this.dialogMessages.prompt(
                "Username cannot be empty",
                this.i18n.tr("error"),
                true,
            );

            return false;
        }

        if (
            !this.editMode &&
            !this.password &&
            this.permissionRole !== "anonymous"
        ) {
            this.dialogMessages.prompt(
                "Password cannot be empty",
                this.i18n.tr("error"),
                true,
            );

            return false;
        }

        if (!this.permissionRole) {
            this.dialogMessages.prompt(
                "Permission role not selected",
                this.i18n.tr("error"),
                true,
            );

            return false;
        }

        return true;
    }

    fillData() {
        this.userData.username = this.username;
        this.userData.givenName = this.firstName;
        this.userData.familyName = this.lastName;
        this.userData.accountDisabled = !this.isActive;
        this.userData.systemUser = this.isSystem;
        this.userData.authorities = [];

        if (this.password /*&& this.permissionRole !== 'anonymous'*/) {
            this.userData.password = this.password;
        }

        switch (this.permissionRole) {
            case "designer": {
                this.userData.authorities = [
                    { permission: "ROLE_FHIR_CLIENT" },
                    { permission: "CHANGE_OWN_PASSWORD" },
                    { permission: "FHIR_ALL_WRITE" },
                    { permission: "FHIR_ALL_DELETE" },
                    { permission: "FHIR_ALL_READ" },
                    { permission: "ACCESS_ADMIN_JSON" },
                    { permission: "FHIR_TRANSACTION" },
                ];
                break;
            }
            case "admin": {
                this.userData.authorities = [{ permission: "ROLE_SUPERUSER" }];
                break;
            }
            case "anonymous": {
                this.userData.authorities = [
                    { permission: "ROLE_ANONYMOUS" },
                    { permission: "FHIR_CAPABILITIES" },
                ];
                break;
            }
            default:
            case "trainee":
            case "doctor":
            case "user": {
                this.userData.authorities = User.DefaultUserAuthorities;
                break;
            }
        }

        if (this.currentUserIsAnonymous()) {
            return;
        }

        this.practitionerData.active = this.isActive;

        if (this.firstName || this.lastName) {
            this.practitionerData.name = [
                {
                    use: "official",
                    prefix: [this.namePrefix],
                    given: [this.firstName],
                    family: this.lastName,
                },
            ];
        }

        if (this.mobilePhone) {
            this.practitionerData.telecom = [
                {
                    system: "phone",
                    value: this.mobilePhone,
                    use: "mobile",
                },
            ];
        }

        if (this.gender) {
            this.practitionerData.gender = this.gender;
        } else {
            delete this.practitionerData.gender;
        }

        if (this.dateObject) {
            let d = moment(this.dateObject).toDate();
            this.practitionerData.birthDate = `${d.getFullYear()}-${NitTools.ToString(d.getMonth(), 2, "0")}-${NitTools.ToString(d.getDate(), 2, "0")}`;
        } else {
            delete this.practitionerData.birthDate;
        }

        this.practitionerRoleData.location = this.wards
            .filter((ward) => ward.isSelected && ward.id !== "patient_released")
            .map((ward) => {
                const location: any = {
                    reference: `Location/${ward.id}`,
                };

                if (ward.id === this.startupWard) {
                    location.identifier = {
                        system:
                            RuntimeInfo.SystemHeader +
                            "/practitioner-default-ward",
                        value: "default",
                    };
                }

                return location;
            });

        this.practitionerData.qualification = [];

        for (const q of this.qualifications.filter(
            (o) => o.selected === true,
        )) {
            this.practitionerData.qualification.push({
                code: {
                    text: `${q.value}: ${q.text}`,
                    coding: [
                        {
                            code: q.value,
                            display: q.text,
                        },
                    ],
                },
            });
        }

        if (
            this.practitionerData?.birthDate &&
            this.practitionerData.birthDate.indexOf("T") > -1
        ) {
            this.practitionerData.birthDate =
                this.practitionerData.birthDate.split("T")[0];
        }
    }

    defaultPW() {
        this.password = RuntimeInfo.Features.autoUserCreation.defaultPassword;
    }

    async forcePWChange() {
        if (!this.practitionerRoleData) {
            this.dialogMessages.prompt(
                "No Practitioner/Role found.<br/> Check Practitioner",
                "Not possible",
                true,
            );
            return;
        }

        try {
            RuntimeInfo.IsLoading = true;
            let ext = Fhir.Tools.GetOrCreateExtension(
                this.practitionerRoleData,
                "authorization/needsPasswordChange",
                true,
            );
            ext.valueString = "true";
            await this.fhirService.update(this.practitionerRoleData);

            this.dialogMessages.prompt(
                "User updated and needs to change Password next time calling visitExtended.",
                "Successfully Changed",
                false,
            );
        } catch (error) {
            if (error.response) {
                this.dialogMessages.showHttpError(error);
            } else {
                console.warn(error.message || JSON.stringify(error));
                this.dialogMessages.prompt(
                    "Error when updating PractitionerRole",
                    "Warning",
                    true,
                );
            }
        } finally {
            RuntimeInfo.IsLoading = false;
        }
    }

    async submit(): Promise<boolean> {
        if (!this.validate()) {
            console.warn("User-Validation failed");
            return false;
        }

        this.isSaving = true;

        if (this.editMode) {
            this.fillData();

            await this.smileService
                .updateUser(this.userData)
                .then(async (result) => {
                    if (result.error) {
                        this.isSaving = false;
                        this.dialogMessages.prompt(
                            result.error.field + ": " + result.error.message,
                            this.i18n.tr("error"),
                            true,
                        );

                        return;
                    }

                    if (!this.currentUserIsAnonymous()) {
                        if (this.practitionerData) {
                            if (!this.practitionerData.identifier)
                                this.practitionerData.identifier = [];

                            if (this.permissionRole) {
                                const role = {
                                    system: `${RuntimeInfo.SystemHeader}/smile-user-role`,
                                    value: this.permissionRole,
                                };

                                this.practitionerData.identifier =
                                    this.practitionerData.identifier.filter(o => o.system.indexOf('/smile-user-role') === -1);

                                this.practitionerData.identifier.push(role);
                            }

                            if (this.userData) {
                                if (this.userData.pid) {
                                    let userPid = this.practitionerData.identifier.find(o => o.system.endsWith('/smile-user-pid'));
                                    if (!userPid) {
                                        userPid = {
                                            system: `${NitTools.ExcludeTrailingSlash(RuntimeInfo.SystemHeader)}/smile-user-pid`
                                        };

                                        this.practitionerData.identifier.push(userPid);
                                    }

                                    userPid.value = this.userData.pid;
                                }

                                if (this.userData.pid) {
                                    let accountId = this.practitionerData.identifier.find((o) =>o.system.endsWith('/smile-account-id'));
                                    if (!accountId) {
                                        accountId = {
                                            system: `${NitTools.ExcludeTrailingSlash(RuntimeInfo.SystemHeader)}/smile-account-id`
                                        };

                                        this.practitionerData.identifier.push(
                                            accountId,
                                        );
                                    }

                                    accountId.value = this.userData.username;
                                }
                            }
                        }

                        await Fhir.Rest.Bundle(
                            [this.practitionerData, this.practitionerRoleData],
                            HTTPVerb.put,
                            BundleType.transaction,
                        );
                    }

                    if (this.prompt)
                        this.dialogMessages
                            .prompt("User updated", this.i18n.tr("success"))
                            .whenClosed(() => {
                                this.router.navigate("users");
                            });

                    return true;
                })
                .catch((response) => {
                    this.isSaving = false;
                    console.warn(
                        response.message || response.response || response,
                    );
                    this.dialogMessages.prompt(
                        "An error occured",
                        this.i18n.tr("error"),
                        true,
                    );
                    return false;
                });
        } else {
            this.userData = {};

            this.practitionerData = {
                resourceType: fhirEnums.ResourceType.practitioner,
                active: !this.userData.accountDisabled,
            };

            this.practitionerRoleData = {
                resourceType: fhirEnums.ResourceType.practitionerRole,
                active: true,
            };

            this.fillData();

            await this.smileService
                .createUser(this.userData)
                .then(async (result) => {
                    if (result.error) {
                        this.isSaving = false;
                        this.dialogMessages.prompt(
                            result.error.field + ": " + result.error.message,
                            this.i18n.tr("error"),
                            true,
                        );

                        return false;
                    }

                    this.userData = <ISmileUser>result;

                    if (!this.currentUserIsAnonymous()) {
                        this.practitionerData.identifier = [
                            {
                                system:
                                    RuntimeInfo.SystemHeader +
                                    "/smile-account-id",
                                value: this.userData.username.toUpperCase(),
                            },
                            {
                                system: `${RuntimeInfo.SystemHeader}/smile-user-role`,
                                value: this.permissionRole,
                            },
                            {
                                system:
                                    RuntimeInfo.SystemHeader +
                                    "/smile-user-pid",
                                value: this.userData.pid,
                            },
                        ];

                        this.practitionerData = <any>(
                            await Fhir.Rest.Create(this.practitionerData)
                        );

                        this.practitionerRoleData.practitioner = {
                            reference: `Practitioner/${this.practitionerData.id}`,
                        };

                        this.practitionerRoleData = <any>(
                            await Fhir.Rest.Create(this.practitionerRoleData)
                        );

                        if (this.prompt)
                            this.dialogMessages
                                .prompt("User created", this.i18n.tr("success"))
                                .whenClosed(() => {
                                    this.router.navigate("users");
                                });

                        return true;
                    }
                })
                .catch((response) => {
                    this.isSaving = false;
                    this.dialogMessages.prompt(
                        "An error occured",
                        `${response.message || this.i18n.tr("error")}`,
                        true,
                    );
                    return false;
                });
        }
    }

    selectAllWards() {
        this.wards.forEach((o) => (o.isSelected = true));
    }

    selectNoWards() {
        this.wards.forEach((o) => (o.isSelected = false));
    }

    currentUserIsAdmin() {
        return (
            typeof this.userData.authorities.find(
                (o) => o.permission === "ROLE_SUPERUSER",
            ) !== "undefined"
        );
    }

    currentUserIsAnonymous() {
        return (
            typeof this.userData.authorities.find(
                (o) => o.permission === "ROLE_ANONYMOUS",
            ) !== "undefined"
        );
    }
}

export interface IUserDomain {
    role: string;
    title: string;
}
